import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Card from 'react-bootstrap/esm/Card';
import Form from 'react-bootstrap/esm/Form';
import Alert from 'react-bootstrap/esm/Alert';
import ListGroup from 'react-bootstrap/esm/ListGroup';
import Button from 'react-bootstrap/esm/Button';

import { IContentForm, IPlaylistForm } from '@/features/Schedule/types';
import { useToggle } from '@/hooks';
import { IPlaylistResponse } from '@/types/playlist';

interface CardDetailsFrequencyWeightProps {
  item: IContentForm | IPlaylistForm | null,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  disabledFrequencyWeight: boolean;
}

const FrequencyControlledByPlaylist = ({ playlistList }: { playlistList: IPlaylistResponse[] }) => {
  const { show: showContentPlaylists, hide: hideContentPlaylists, isVisible: isContentPlaylistsVisible } = useToggle();

  return (
    <div className="mb-3">
      <Alert variant="secondary">
        <FontAwesomeIcon icon="circle-info" className="me-2" />
        Content in a playlist inherits that playlist&rsquo;s frequency weight.
      </Alert>
      <Button
        variant="link"
        onClick={isContentPlaylistsVisible ? hideContentPlaylists : showContentPlaylists}
      >
        {isContentPlaylistsVisible ? 'Hide' : 'View'} All Parent Playlists
      </Button>
      {isContentPlaylistsVisible && (
        <ListGroup>
          {playlistList.map((playlist) => {
            return (
              <ListGroup.Item key={playlist.id} className="d-flex justify-content-between">
                <div>{playlist.name}</div>
                <div className="mx-3">{playlist.frequencyWeight}</div>
              </ListGroup.Item>);
          })}
        </ListGroup>
      )}
    </div>
  );
};

const CardDetailsFrequencyWeight = ({
  item,
  setFieldValue,
  disabledFrequencyWeight,
}: CardDetailsFrequencyWeightProps) => {
  // @ts-expect-error
  const playlistList = item?.playlists && item?.playlists.length > 0 ? item.playlists : [];

  return (
    <Card className="bg-white shadow-sm">
      <Card.Body>
        <Card.Title>Frequency Weight</Card.Title>
        <div>
          <img
            src="/frequency-slider-grayscale.jpg"
            alt="Frequency Slider Example"
            className="w-100"
          />
        </div>
        <div className="mt-3">
          {(item && item.name) && (
            <>
              <Form.Label
                htmlFor="frequencyWeight"
                className="fw-bold mb-0 text-wrap"
              >
                {item.name}
              </Form.Label>
            </>
          )}
          <Form.Range
            id="frequencyWeight"
            name="frequencyWeight"
            value={item?.frequencyWeight}
            disabled={disabledFrequencyWeight}
            onChange={(e: any) => setFieldValue('frequencyWeight', parseInt(e.target.value, 10))}
            min={1}
            max={10}
          />
          <div className="d-flex justify-content-between text-light-blue">
            <Form.Label className="frequency-range-label">Play Less</Form.Label>
            <Form.Label className="frequency-range-label">Play More</Form.Label>
          </div>
          <div>{item?.frequencyWeight}</div>
        </div>
        {disabledFrequencyWeight && (<FrequencyControlledByPlaylist playlistList={playlistList} />)}
      </Card.Body>
    </Card>
  );
};

export default CardDetailsFrequencyWeight;
